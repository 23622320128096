<template>
  <v-app>
    <!-------- App Bar -------->
    <v-app-bar app class="white white--text text-center" dark>
      <v-img
        class="mx-2"
        :src="'/img/'+ empresasLogo"
        max-height="120"
        max-width="120"
        contain
      ></v-img>
      <v-spacer></v-spacer>
      <v-img
        class="mx-2"
        :src="path_img2"
        max-height="90"
        max-width="90"
        contain
      ></v-img>
    </v-app-bar>
    <!--./App Bar --->
    <div class="wrapper" id="app">
      <div class="card-form" style="margin-top:20px">
        <!-- TARJETA CSS -->
        <div class="card-list">
          <div class="card-item" v-bind:class="{ '-active' : isCardFlipped }">
            <div class="card-item__side -front">
              <div class="card-item__focus" v-bind:class="{'-active' : focusElementStyle }" v-bind:style="focusElementStyle" ref="focusElement"></div>
              <div class="card-item__cover">
                <img v-bind:src="'/img/jpeg/' + cardLocation" class="card-item__bg">
              </div>
              <div class="card-item__wrapper">
                <div class="card-item__top">
                  <img :src="'/img/png/' + chipLocation" class="card-item__chip">
                </div>
                <the-mask 
                  v-model="cardNumber"
                  :mask="['####-####-####-####','####-####-####-###', '####-####-#####-####' ]"
                  class="card-item__number"
                  ref="cardNumber"
                  for="cardNumber"
                  readonly
                />
                <div class="card-item__content">
                  <label for="cardName" class="card-item__info" ref="cardName">
                    <div class="card-item__holder">Titular</div>
                    <transition name="slide-fade-up">
                      <div class="card-item__name" v-if="cardName.length" key="1">
                        <transition-group name="slide-fade-right">
                          <span class="card-item__nameItem" v-for="(n, $index) in cardName.replace(/\s\s+/g, ' ')" v-bind:key="$index + 1">{{n}}</span>
                        </transition-group>
                      </div>
                      <div class="card-item__name" v-else key="2">Nombre Completo</div>
                    </transition>
                  </label>
                  <div class="card-item__date" ref="cardDate">
                    <label for="cardMonth" class="card-item__dateTitle">Expira</label>
                    <label for="cardMonth" class="card-item__dateItem">
                      <transition name="slide-fade-up">
                        <span v-if="cardMonth" v-bind:key="cardMonth">{{cardMonth}}</span>
                        <span v-else key="2">MM</span>
                      </transition>
                    </label>
                    /
                    <label for="cardYear" class="card-item__dateItem">
                      <transition name="slide-fade-up">
                        <span v-if="cardYear" v-bind:key="cardYear">{{String(cardYear).slice(2,4)}}</span>
                        <span v-else key="2">AA</span>
                      </transition>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-item__side -back">
              <div class="card-item__cover">
                <img v-bind:src="'/img/jpeg/' + cardLocation" class="card-item__bg">
              </div>
              <div class="card-item__band"></div>
              <div class="card-item__cvv">
                <div class="card-item__cvvTitle">CVV</div>
                <div class="card-item__cvvBand">
                  <span v-for="(n, $index) in cardCvv" :key="$index">
                    *
                  </span>
                </div>
                <div class="card-item__type">
                  <!-- <img v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'" v-if="getCardType" class="card-item__typeImg"> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- DATOS DEL PAGO -->
        <div class="card-form__inner">
          <div class="card-form__row">
            <div class="card-form__col">
              <v-form ref="form" v-model="valid" lazy-validation>
                <div class="card-form__group justify-center">
                  <!-- Importe -->
                  <v-col cols="6" sm="4" md="4">
                    <v-text-field
                      v-model="total"
                      color="info"
                      style="font-size:25px; text-align: center"
                      type="number" prefix="$"
                      label="Importe"
                      :rules="Rules"
                      outlined  readonly
                    ></v-text-field>
                  </v-col>
                  <!-- Importe -->
                  <v-col cols="6" sm="4" md="4">
                    <v-text-field
                      v-model="importe_total"
                      label="Importe Final"
                      color="info"
                      style="font-size:25px; text-align: center"
                      type="number"  prefix="$"
                      outlined readonly
                    ></v-text-field>
                  </v-col>
                </div>
                <label style="margin-left:12px;font-size:12px"><b>*Todos los campos son obligatorios</b></label>
                <div class="card-form__group">
                  <!-- Tipo Tarjeta -->
                  <v-col cols="12" sm="6" md="6">
                    <label for="cardName" class="card-input__label">Tipo Tarjeta</label>
                    <v-autocomplete
                      v-model="tipo_tarjeta"
                      :items="optionsTarjetas"
                      item-text="TARJETA"
                      return-object
                      :rules="Rules"
                      placeholder="Seleccione tarjeta"
                      @change="filterFinanciacion()"
                      persistent-hint dense
                      outlined  required clearable deletable-chips
                      :disabled="datosBoton.ingresa_financiacion == 1"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Forma de Pago -->
                  <v-col cols="12" sm="6" md="6" class="spaceInput" >
                    <label for="cardName" class="card-input__label">Forma de Pago</label>
                    <v-autocomplete
                        v-model="forma_pago"
                        :items="optionsFormaPagoFilter"
                        item-text="FINANCIACION"
                        return-object
                        :rules="Rules"
                        placeholder="Seleccione forma de pago"
                        @change="calcularTotal()"
                        persistent-hint dense
                        outlined  required clearable  deletable-chips
                        :disabled="datosBoton.ingresa_financiacion == 1"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Tarjeta Numero -->
                  <v-col cols="12" sm="12" md="12" style="margin-top:-30px">
                    <label for="cardNumber" class="card-input__label">Tarjeta Numero</label>
                    <the-mask 
                      v-model="cardNumber"
                      :mask="['####-####-####-####','####-####-####-###', '####-####-#####-#####' ]"
                      class="card-input__input"
                    />
                  </v-col>
                </div>
                <div class="card-form__group">
                  <!-- Titular -->
                  <v-col cols="12" sm="12" md="12" style="margin-top:-15px">
                    <label for="cardName" class="card-input__label">Titular</label>
                    <input 
                      v-model="cardName"  type="text"  v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardName" autocomplete="off"
                      placeholder="Como figura en la tarjeta"
                      id="cardName"
                      class="card-input__input" >
                  </v-col>
                </div>
                <div class="card-form__group">
                  <!-- Mes -->
                  <v-col cols="6" sm="3" md="3">
                    <label for="cardMonth" class="card-input__label">Vencimiento</label>
                    <select class="card-input__input -select" id="cardMonth" v-model="cardMonth" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                      <option value="" disabled selected>Mes</option>
                      <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth" v-bind:key="n">
                              {{n < 10 ? '0' + n : n}}
                      </option>
                    </select>
                  </v-col>
                  <!-- Año -->
                  <v-col cols="6" sm="3" md="3" >
                    <label for="cardMonth" class="card-input__label">&nbsp;</label>
                    <select class="card-input__input -select" id="cardYear" v-model="cardYear" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                      <option value="" disabled selected>Año</option>
                      <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                              {{$index + minCardYear}}
                      </option>
                    </select>
                  </v-col>
                  <!-- CVV -->
                  <v-col cols="6" sm="3" md="3" >
                    <label for="cardCvv" class="card-input__label">Código Seguridad</label>
                    <input 
                      v-model="cardCvv"
                      type="text"
                      class="card-input__input"
                      id="cardCvv"
                      v-mask="'####'"
                      maxlength="4"
                      v-on:focus="flipCard(true)"
                      v-on:blur="flipCard(false)"
                      autocomplete="off"
                    >
                  </v-col>
                  <!-- Documento -->
                  <v-col cols="6" sm="3" md="3" >
                    <label  class="card-input__label">Documento</label>
                    <input  v-model="documento"  type="text" class="card-input__input">
                  </v-col>
                  <!-- Documento -->
                  <v-col cols="12" sm="6" md="6" class="spaceInput" >
                    <label for="cardName" class="card-input__label">Correo</label>
                    <v-text-field
                      v-model="correo"
                      :rules="Rules"
                      placeholder="Ingrese su correo"
                      validate-on-blur
                      persistent-hint
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </div>
              </v-form>
            </div>
          </div>
          <v-btn class="card-form__button" color="primary" block @click="confirmarPago()" :disabled="!puedePagar">Procesar Pago</v-btn>
        </div>
      </div>
      <!-- Modal Confirmar Cobro -->
      <div class="text-center">
        <v-dialog v-model="dialogConfirm" max-width="600px">
          <v-card>
            <v-card-title class="subtitle-1 indigo">
              <v-icon left color="white">mdi-checkbox-marked-circle-outline</v-icon>
              <span class="subtitle-1" style="color:white">Datos del Pago</span>
            </v-card-title>
            <v-card-text>
              <v-container fluid>
                <v-row justify="center">
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="importe_total"
                      color="info"
                      style="font-size:23px; text-align: center"
                      type="number"
                      prefix="$"  readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="8" md="8" style="margin-top:-30px">
                    <v-text-field
                        v-model="cardName"
                        label="Titular"
                        outlined dense readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" style="margin-top:-30px">
                    <v-text-field
                      v-model="documento"
                      label="Documento"
                      outlined dense readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" style="margin-top:-45px">
                    <label for="">Tarjeta</label>
                    <the-mask 
                      v-model="cardNumber"
                      :mask="['####-####-####-####','####-####-####-###', '####-####-#####-####' ]"
                      class="card-input__input"
                      readonly
                      style="height:38px;border: 1px solid #A4A4A4;color: #2E2E2E"
                    />
                  </v-col>
                  <v-col cols="6" sm="4" md="4">
                    <v-text-field
                      v-model="cardMonth"
                      label="Mes"
                      outlined  dense  readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="4" md="4">
                    <v-text-field
                      v-model="cardYear"
                      label="Año"
                      outlined dense readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                        v-model="cardCvv"
                        label="Codigo Seguridad"
                        outlined dense readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" style="margin-top:-30px">
                    <v-text-field
                      v-model="forma_pago_descripcion"
                      outlined dense readonly
                      label="Forma de Pago"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="d-flex justify-center mx-2">
              <v-btn
                style="float: left;"
                color="red darken-1" dark outlined
                @click="dialogConfirm = false"
              >VOLVER</v-btn>
              <v-btn
                style="float: rigth;"
                color="success darken-1" dark             
                @click="procesarPago()"
              >CONFIRMAR</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- Modal Peticiones  -->
      <v-dialog v-model="modalSendPago" persistent width="300">
        <v-card color="primary" dark>
          <v-card-title>
            <v-row align="center" justify="center">    
              {{ tituloModal}}
            </v-row>
          </v-card-title>
          <br/>
          <v-card-text>
            <v-row align="center" justify="center">    
              <v-progress-circular
                color="red"
                indeterminate
                ></v-progress-circular>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--Modal Pago Success  -->
      <v-dialog  v-model="dialogSuccess" persistent width="450">                              
        <v-card>
          <v-col cols="12" sm="12" md="12"> 
            <v-row align="center" justify="center">                    
              <v-container  width="750" raised><br/>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                      <v-img  class="mx-4" :src="pathLogoSuccess"  max-height="90" max-width="100" contain></v-img>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <br/>
                  <div  class="text-center">
                    <p  class="titleSuccesMsj">Pago Realizado Correctamente</p>                                     
                  </div>                                
                  <v-card-text  class="text-center">                                                            
                    <p  class="textSuccesMsj"> Importe : $ {{  respuestaTransaccion.importe}}</p>   
                    <p  class="textSuccesMsj"> Fecha Pago :  {{  respuestaTransaccion.fecha_cobro }}</p>   
                    <p  class="textSuccesMsj"> Transaccion :  {{  respuestaTransaccion.Ipg_TransaccionID }}</p>   
                    <v-row>
                      <v-spacer>
                      <v-btn small  dark  color="info" @click="descargarComprobantePago()">
                          <v-icon small>mdi-arrow-down</v-icon>&nbsp; 
                          Descargar comprobante
                      </v-btn>
                      </v-spacer>
                    </v-row>
                    <br/>
                    <v-row>
                      <v-spacer>
                        <v-btn small  dark color="red" @click="closeDialogSuccess()">Cerrar</v-btn>
                      </v-spacer>
                    </v-row>
                  </v-card-text>                                               
              </v-container>                 
            </v-row>
        </v-col>
          </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<style src="./credit_card.css"></style>
<script>
import { cadenaVacia, roundNumber, objetoNoVacio } from '../../util/util'
export default {
  data() {
    return {
      empresasLogo: {},
      path_img2: '/img/compra_segura.png',
      path_img1: '/img/logo_dycar.jpeg',
      isCardFlipped: false,
      focusElementStyle: null,
      currentCardBackground: Math.floor(Math.random()* 10 + 1),
      cardNumber: '',
      empresa: 0,
      idpago: '',
      datosBoton: {},
      datosEmpresa: {},
      optionsFormaPago: [],
      optionsTarjetas: [],
      estadosCobro: [],
      link_accesible: 0,
      correo: '',
      tipo_tarjeta: '',
      optionsFormaPagoFilter: [],
      forma_pago: '',
      importe_total: '',
      cardName: '',
      cardYear: '',
      cardMonth: '',
      valid: true,
      total: '',
      Rules: [
        v => !!v || "Campo requerido.",
      ],
      isInputFocused: false,
      minCardYear: new Date().getFullYear(),
      cardCvv: '',
      documento: '',
      chipLocation: '',
      cardLocation: '',
      dialogConfirm: false,
      modalSendPago: false,
      tituloModal: '',
      pathLogoSuccess:"/img/success_logo.png",
      respuestaTransaccion: {},
      cardNumberTemp: '',
      amexCardMask: "#### #### #### ####",
      otherCardMask: "#### #### #### ####",
      forma_pago_descripcion: '',
      dialogSuccess: false,
      errors: false,
      puedePagar: true
    }
  },
  created () {
    this.cardLocation = this.currentCardBackground + '.jpeg'
    this.chipLocation = 'chip.png'
    this.getBotonPago()
    //this.checkApiPayment()
  },
  mounted () {
    this.cardNumberTemp = this.otherCardMask
    //document.getElementById("cardNumber").focus()
  },
  computed: {
    getCardType () {
      let number = this.cardNumber
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      return "visa"; // default type
    },
    minCardMonth () {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1
      return 1
    },
    generateCardNumberMask(){
      return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
    },
  },
  methods: {
    async getBotonPago(){
      this.empresa = this.$route.params.empresa
      this.idpago = this.$route.params.id
      if (cadenaVacia(this.empresa) || this.empresa == 0 || cadenaVacia(this.idpago) || this.idpago == 0){
        // el link no tiene los parametros necesarios para iniciar el cobro
        this.$store.state.msjSnackBar = 'No se pudo iniciar el Cobro.'
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        this.puedePagar = false
        return
      }
      // paso a obtener los datos del boton de pago
      this.$store.state.modalCarga = true
      let botonPeticion = await this.$store.dispatch('botonPago/getUnBotonPago', {empresa: this.empresa, key: this.idpago})
      if (botonPeticion.resultado == 0){
        this.puedePagar = false
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = 'Ocurrio un problema al obtener los datos del cobro: ' + botonPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
      this.datosBoton = botonPeticion.boton
      // asigno el total
      this.total = this.datosBoton.importe
      // 29-08-2024 por MMURILLO, controlo si el botón de pago ya fué aprobado
      let statusPeticion = await this.$store.dispatch('cobros/getPaymentStatus', {
        key_firebase: this.idpago,
        empresa_codigo: this.empresa
      })
      if (statusPeticion.resultado == 0){
        this.puedePagar = false
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = statusPeticion.message
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
      if ((statusPeticion.estado == 2 || statusPeticion.estado == 4) && this.datosBoton.estado.toString().toUpperCase() != 'APROBADO' && this.datosBoton.estado.toString().toUpperCase() != 'ANULADO'){
        this.puedePagar = false
        let updatePeticion = await this.$store.dispatch('botonPago/updateBoton', {
          key_firebase: this.idpago,
          empresa_codigo: this.empresa,
          estado: statusPeticion.estado,
          marca: statusPeticion.marca,
          importe: roundNumber(statusPeticion.importe, 2),
          tdate: statusPeticion.tdate,
          transaccion_id: statusPeticion.transaccion_id,
          numero: statusPeticion.numero,
          lote: statusPeticion.lote,
          codigo_autorizacion: statusPeticion.codigo_autorizacion,
          titular: statusPeticion.titular,
          documento: statusPeticion.documento,
          nro_tarjeta: statusPeticion.nro_tarjeta,
          usuario_uid: this.datosBoton.usuario_uid,
          descripcion: this.datosBoton.descripcion,
          telefono: this.datosBoton.telefono,
          link: this.datosBoton.link,
          fecha_log: this.datosBoton.fecha_log,
          ingresa_financiacion: this.datosBoton.ingresa_financiacion,
          ingresa_forma_pago: this.datosBoton.ingresa_forma_pago,
          ingresa_tipo_tarjeta: this.datosBoton.ingresa_tipo_tarjeta,
          correo: this.datosBoton.correo
        })
        if (updatePeticion.resultado == 0){
          this.$store.state.modalCarga = false
          this.$store.state.msjSnackBar = 'Ocurrió un problema al actualizar los datos del Botón de Pago. Intente nuevamente más tarde.'
          this.$store.state.colorSnackBar = 'error'
          this.$store.state.snackBarActivo = true
          return
        }
        this.$store.dispatch('executeModalAviso', {titulo: 'Ejecutar el Cobro', texto: 'Se actualizaron los datos de su Cobro. La página se recargará en breve.', icono: 'fas fa-exclamation-circle', color: 'success'})
        setTimeout(() => [location.reload()], 3000)
        return
      }
      // si el pago sigue pendiente y es rada hq lo actualizo
      if (this.datosBoton.estado == 'PENDIENTE' && this.datosBoton.usuario_uid == 'HQ') {
        let result = await this.$store.dispatch('botonPago/getReservaWeb', this.datosBoton.reserva)

        if (!result.exito) {
          this.puedePagar = false
          this.$store.state.modalCarga = false
          this.$store.state.msjSnackBar = 'No se pudo obtener los datos de la reserva: ' + result.message
          this.$store.state.colorSnackBar = 'error'
          this.$store.state.snackBarActivo = true
          return
        }

        if (roundNumber(result.total, 2) != roundNumber(this.datosBoton.importe, 2)) {
          let updatePeticion = await this.$store.dispatch('botonPago/actualizarDatos', {
            key_firebase: this.idpago,
            importe: roundNumber(result.total, 0),
            telefono: this.datosBoton.telefono,
            correo: this.datosBoton.correo
          })

          if (updatePeticion.resultado == 0) {
            this.puedePagar = false
            this.$store.state.modalCarga = false
            this.$store.state.msjSnackBar = 'Ocurrió un problema al actualizar los datos del Botón de Pago. Intente nuevamente más tarde.'
            this.$store.state.colorSnackBar = 'error'
            this.$store.state.snackBarActivo = true
            return
          }

          this.$store.dispatch('executeModalAviso', {titulo: 'Ejecutar el Cobro', texto: 'Se actualizaron los datos de su Cobro. La página se recargará en breve.', icono: 'fas fa-exclamation-circle', color: 'success'})
          setTimeout(() => [location.reload()], 3000)
          return
        }
      }
      // obtengo los estados de cobro
      let estadosPeticion = await this.$store.dispatch('cobros/getEstadosCobro')
      if (estadosPeticion.resultado == 0){
        this.puedePagar = false
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = 'Ocurrio un problema al obtener los estados del cobro: ' + estadosPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
      this.estadosCobro = estadosPeticion.estados
      this.getEmpresas()
    },
    async getEmpresas(){
      let empresaPeticion = await this.$store.dispatch('firebaseService/getEmpresaInfo', {empresa: this.empresa})
      if (empresaPeticion.resultado == 1){
        // todo ok
        this.datosEmpresa = empresaPeticion.empresa
        this.empresasLogo = this.datosEmpresa.logo
        if (this.datosBoton.estado.toUpperCase() == 'APROBADO'){
          this.$store.dispatch('executeModalAviso', {titulo: 'Iniciando Cobro.', texto: 'Su cobro ya se encuentra APROBADO.', icono: 'fas fa-exclamation-circle', color: 'info'})
          setTimeout(() => [window.location.href = this.datosEmpresa.redireccion], 3000)
          return
        }else if (this.datosBoton.estado.toUpperCase() == 'INHABILITADO'){
          this.$store.dispatch('executeModalAviso', {titulo: 'Iniciando Cobro.', texto: 'Su cobro se encuentra INHABILITADO, no se puede proceder.', icono: 'fas fa-exclamation-circle', color: 'info'})
          setTimeout(() => [window.location.href = this.datosEmpresa.redireccion], 3000)
          return
        }else{
          this.getFinanciaciones()
        }
        //this.checkApiPayment()
      }else{
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = 'No se pudo iniciar el Cobro: ' + empresaPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
    },
    async getFinanciaciones(){
      // obtengo las financiaciones
      let tarjetasVigentes = []
      for (let id in this.datosEmpresa.financiaciones){
        let tarjeta = this.datosEmpresa.financiaciones[id]
        if (tarjeta != null && tarjeta != 'null' && tarjeta != "null"){
          if (tarjeta.VIGENTE == 1){
            tarjetasVigentes.push(tarjeta)
          }
        }
      }
      this.optionsFormaPago = tarjetasVigentes
      // obtengo las tarjetas
      let tarjetasPeticion = await this.$store.dispatch('cobros/getTarjetas')
      if (tarjetasPeticion.resultado == 1){
        this.optionsTarjetas = tarjetasPeticion.tarjetas
        // ordeno el listado de tarjetas
        this.optionsTarjetas.sort(function(a,b){
          if (a.TARJETA < b.TARJETA) {
            return -1;
          }
          if (a.TARJETA > b.TARJETA) {
            return 1;
          }
          // a must be equal to b
          return 0;
        })
      }else{
        // error al obtener las tarjetas
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = 'No se pudo obtener los datos de las tarjetas: ' + tarjetasPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
      this.typePayment()
    },
    validarLink(){
      if(this.link_accesible.accesible == 0){
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = 'No se puede acceder al pago, Estado actual: ' + this.datosBoton.estado
        this.$store.state.colorSnackBar = 'warning'
        this.$store.state.snackBarActivo = true
        // redireccionar
        setTimeout(() => [window.location.href = 'https://radarentacar.com.ar/'], 3000)
      }
    },
    typePayment(){
      this.correo = this.datosBoton.correo
      if (this.datosBoton.ingresa_financiacion == 1){
        this.tipo_tarjeta = this.datosBoton.ingresa_tipo_tarjeta
        this.optionsFormaPagoFilter.push(this.datosBoton.ingresa_forma_pago)
        this.forma_pago = this.datosBoton.ingresa_forma_pago
        this.importe_total = this.datosBoton.importe
      }
      this.$store.state.modalCarga = false
    },
    async checkApiPayment(){
      let checkBotonPeticion = await this.$store.dispatch('botonPago/checkApiPayment', {key_firebase: this.idpago, tipo_pedido: 19, importe: this.convertDecimals(this.importe_total), tarjeta: this.cardNumber})
      if (checkBotonPeticion.resultado == 1){
        if (parseInt(checkBotonPeticion.estados[0].cant_anulados) > 0){
          // el botón se anuló, completar los datos
          this.$store.dispatch('executeModalAviso', {titulo: 'Iniciando Botón de Pago', texto: 'El Botón de Pago se encuentra Anulado. No se puede proceder con el cobro.', icono: 'fas fa-exclamation-circle', color: 'info'})
          // paso a completar la anulación
          setTimeout(() => [this.completarAnulacionBotonPago({id: this.idpago})], 3000)
        }
        if (parseInt(checkBotonPeticion.estados[0].cant_aprobados) > 0){
          // el botón ya se aprobó, completar los datos
          this.$store.dispatch('executeModalAviso', {titulo: 'Iniciando Botón de Pago', texto: 'El Botón de Pago se encuentra Aprobado. Se procederá a completar los datos restantes.', icono: 'fas fa-exclamation-circle', color: 'info'})
          setTimeout(() => [this.aprobarBotonPago(checkBotonPeticion.cobro[0])], 3000)
        }
      }else{
        // ocurrio un problema al obtener el estado del boton en la api de jarvis
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = 'No se pudo obtener el estado actual del botón de pago: ' + checkBotonPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        setTimeout(() => [window.location.href = this.datosEmpresa.redireccion], 3000)
      }
    },
    async aprobarBotonPago(datosCobro){
      this.$store.state.modalCarga = true
      datosCobro.key = this.idpago
      datosCobro.empresa = this.empresa
      let completarPeticion = await this.$store.dispatch('botonPago/aprobarBotonPago', datosCobro)
      this.$store.state.modalCarga = false
      if (completarPeticion.resultado == 1){
        this.$store.state.msjSnackBar = 'Se completó exitosamente el Botón de Pago. En unos instantes recibirá mail con el comprobante de pago.'
        this.$store.state.colorSnackBar = 'success'
        this.$store.state.snackBarActivo = true
        this.tituloModal = 'Finalizando pago ...'
        this.modalSendPago = true
        setTimeout(() => [this.enviarMail({body: {id: datosCobro.transaccion_id}}, {titular: datosCobro.titular, total: datosCobro.importe})], 3000)
      }else{
        this.$store.state.msjSnackBar = 'No se pudo completar el botón de pago: ' + completarPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        setTimeout(() => [window.location.href = this.datosEmpresa.redireccion], 3000)
      }
    },
    filterFinanciacion(){
      if(parseInt(this.datosBoton.ingresa_financiacion) == 0){
        this.optionsFormaPagoFilter = this.optionsFormaPago.filter( fp => fp.TARJETA_CODIGO == this.tipo_tarjeta.TARJETA_CODIGO )
      }
    },
    calcularTotal(){
      if(parseInt(this.datosBoton.ingresa_financiacion) == 0){
        let interes = 0
        interes = parseFloat(this.total) * (parseFloat(this.forma_pago.PORCENTAJE) / 100)
        this.importe_total = parseFloat(this.total) + parseFloat(interes.toFixed(2))
      }
    },
    focusInput(e){
      this.isInputFocused = true
      let targetRef = e.target.dataset.ref
      let target = this.$refs[targetRef]
      this.focusElementStyle = {
          width: `${target.offsetWidth}px`,
          height: `${target.offsetHeight}px`,
          transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
      }
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
    flipCard (status) {
      this.isCardFlipped = status
    },
    validate(){
      /* Funcion para validar los campos que son obligatorios */
      if (this.$refs.form.validate()) {
        /*Si algunas de las Rules no cumple,la funcion retornara 'false' */
        return (this.errors = true);
      }else {
        return (this.errors = false);
      }
    },
    checkEmail(email){
      var filter = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!filter.test(email)) {
          return false;
      }
      else{
          return true;
      }
    },
    confirmarPago(){
      if (!this.validate()) {
        return;
      }
      if(!this.checkEmail(this.correo.toLowerCase().trim())){
        alert("Verifique el correo ingresado.")
        return
      }
      if(this.cardNumber == "" || this.cardName == "" ||this.cardMonth == "" || this.cardYear == "" || this.cardCvv == "" || this.documento == "" ){
        alert('Complete todos los campos')
        return
      }
      this.forma_pago_descripcion = this.forma_pago. TARJETA +' - '+ this.forma_pago.FINANCIACION
      this.dialogConfirm = true
    },
    convertDecimals(numero){
      return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
    },
    async procesarPago(){
      // NO MAS LOGINS, AHORA USO APIKEY
      /*
      // controlo la validez del token de jarvis
      let invalido = this.$jwt.isExpired(this.$store.state.tokenApi)
      if (invalido == true){
        // token vencido, consigo otro
        let tokenPeticion = await this.$store.dispatch('usuarios/executeLoginApi')
        if (tokenPeticion.resultado == 0){
          // no se pudo hacer login
          this.$store.state.msjSnackBar = 'No se pudo restablecer el token de la sesión: ' + tokenPeticion.msj
          this.$store.state.colorSnackBar = 'error'
          this.$store.state.snackBarActivo = true
          return
        }
      }
      */
      // si el token en valido,  paso a cobrar
      this.tituloModal = 'Procesando Pago ...'
      this.modalSendPago = true
      // ya se cobro?
      let checkBotonPeticion = await this.$store.dispatch('botonPago/checkApiPayment', {key_firebase: this.idpago, tipo_pedido: 19, importe: this.convertDecimals(this.importe_total), tarjeta: this.cardNumber})
      if (checkBotonPeticion.resultado == 1){
        if (parseInt(checkBotonPeticion.estados[0].cant_anulados) > 0){
          this.modalSendPago = false
          // el botón se anuló, completar los datos
          this.$store.dispatch('executeModalAviso', {titulo: 'Ejecutar el Cobro', texto: 'El Botón de Pago se encuentra Anulado. No se puede proceder con el cobro.', icono: 'fas fa-exclamation-circle', color: 'info'})
          // paso a completar la anulación
          setTimeout(() => [this.completarAnulacionBotonPago({id: this.idpago})], 3000)
          return
        }
        if (parseInt(checkBotonPeticion.estados[0].cant_aprobados) > 0){
          this.modalSendPago = false
          // el botón ya se aprobó, completar los datos
          this.$store.dispatch('executeModalAviso', {titulo: 'Ejecutar el Cobro', texto: 'El Botón de Pago se encuentra Aprobado. Se procederá a completar los datos restantes.', icono: 'fas fa-exclamation-circle', color: 'info'})
          setTimeout(() => [this.aprobarBotonPago(checkBotonPeticion.cobro[0])], 3000)
          return
        }
      }else{
        this.modalSendPago = false
        // ocurrio un problema al obtener el estado del boton en la api de jarvis
        this.$store.state.modalCarga = false
        this.$store.state.msjSnackBar = 'No se pudo obtener el estado actual del botón de pago: ' + checkBotonPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        setTimeout(() => [window.location.href = this.datosEmpresa.redireccion], 3000)
        return
      }
      // inicializo los datos de pago del cliente
      this.datosPagoCliente = {}
      let datosPago = {
        idpago: this.idpago,
        empresa: this.empresa,
        titular : this.cardName,
        documento_numero : this.documento,
        tarjeta_numero : this.cardNumber,
        mes_vto : this.cardMonth.toString(),
        anio : this.cardYear.toString().slice(-2),
        codigo_seguridad : this.cardCvv,
        cuotas : this.forma_pago.CUOTA_INTERNA,
        forma_pago: this.forma_pago,
        total : this.convertDecimals(this.importe_total),
        correo : this.correo.toLowerCase().trim(),
        ingresa_financiacion : this.datosBoton.ingresa_financiacion,
        ingresa_tipo_tarjeta : this.datosBoton.ingresa_tipo_tarjeta,
        ingresa_forma_pago : this.datosBoton.ingresa_forma_pago,
        key_boton: this.idpago,
        origen_id: (objetoNoVacio(this.datosBoton) && !cadenaVacia(this.datosBoton.reserva)) ? this.datosBoton.reserva : null,
        origen_tipo_pedido_id: (objetoNoVacio(this.datosBoton) && !cadenaVacia(this.datosBoton.reserva)) ? 25 : null
      }
      this.datosPagoCliente = datosPago
      let cobroPeticion = await this.$store.dispatch('cobros/processPayment', this.datosPagoCliente)
      this.modalSendPago = false
      // controlo la salida del cobro
      let errorSalida = {}
      if (cobroPeticion.resultado == 1){
        // controlo el resultado del cobro
        if (cobroPeticion.datosCobro != null && cobroPeticion.datosCobro.hasOwnProperty('body') && cobroPeticion.datosCobro.body.hasOwnProperty('status') && 
        cobroPeticion.datosCobro.body.status.toUpperCase() == 'APPROVED'){
          // cobro exitoso
          errorSalida = {}
          this.$store.dispatch('executeModalAviso', {titulo: 'Ejecutar el Cobro', texto: 'Su cobro fue procesado con éxito. En breves minutos recibirá su comprobante en el mail.', icono: 'fas fa-exclamation-circle', color: 'success'})
        }else{
          // llamada correcta a la api, pero no se aprobó el cobro
          let msjError = ''
          let msj = ''
          if (cobroPeticion.datosCobro.hasOwnProperty('body') && cobroPeticion.datosCobro.body.hasOwnProperty('status_details') && cobroPeticion.datosCobro.body.status_details.hasOwnProperty('error') &&
          cobroPeticion.datosCobro.body.status_details.error.hasOwnProperty('reason') && cobroPeticion.datosCobro.body.status_details.error.reason.hasOwnProperty('description')){
            msjError = cobroPeticion.datosCobro.body.status_details.error.reason.description
            msj = cobroPeticion.datosCobro.body.status
          }else if(Object.keys(cobroPeticion.datosCobro).length == 3 && cobroPeticion.datosCobro.hasOwnProperty('error') && cobroPeticion.datosCobro.hasOwnProperty('exito') && cobroPeticion.datosCobro.hasOwnProperty('mensaje') && cobroPeticion.datosCobro.error == 1){
            msj = 'rejected'
            msjError = cobroPeticion.datosCobro.mensaje
          }else if (cobroPeticion.datosCobro.hasOwnProperty('error') && cobroPeticion.datosCobro.error.hasOwnProperty('mensaje')){
            msjError = cobroPeticion.datosCobro.error.mensaje
            msj = 'rejected'
          }else{
            msjError = 'Error genérico. No se pudo efectuar su cobro'
            msj = 'rejected'
          }
          this.$store.dispatch('executeModalAviso', {titulo: 'Ejecutar el Cobro', texto: 'Su cobro fue rechazado: ' + msjError, icono: 'fas fa-exclamation-circle', color: 'error'})
          errorSalida = {
            error_codigo: 1,
            error_mensaje: msj,
            error_descripcion: msjError
          }
        }
      }else{
        // cobro no exitoso
        this.$store.state.msjSnackBar = 'Ocurrio un error inesperado al iniciar el cobro: ' + cobroPeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        errorSalida = {
          error_codigo: 2,
          error_mensaje: 'rejected',
          error_descripcion: 'Ocurrio un error inesperado al iniciar el cobro: ' + cobroPeticion.msj
        }
      }
      // completo los datos del boton de pago con los datos del cobro
      this.completarBotonPago(errorSalida, cobroPeticion.datosCobro, datosPago)
    },
    async completarBotonPago(errorSalida, datosCobro, datosPago){
      let unBoton = {}
      let error = 0, error_codigo = 0, error_mensaje = '', error_descripcion = ''
      let status = '', bin = null, card_brand = null, card_data = null, date = null, id = null, site_id = null, site_transaction_id = null, token = null, cupon = null, codigo_autorizacion = null, Ipg_TransaccionID = '00', fecha_cupon = null
      let ingresa_financiacion = 0, ingresa_tipo_tarjeta = 0, ingresa_forma_pago = 0
      if (Object.keys(errorSalida).length > 0){
        // es cobro con error
        error = 1
        error_codigo = errorSalida.error_codigo
        error_mensaje = errorSalida.error_mensaje
        error_descripcion = errorSalida.error_descripcion
        status = errorSalida.error_mensaje
      }else{
        // cobro exitoso
        status = datosCobro.body.status
        bin = datosCobro.body.bin,
        card_brand = datosCobro.body.card_brand,
        card_data = datosCobro.body.card_data,
        date = datosCobro.body.date,
        id = datosCobro.body.id, // transaccion_id
        site_id = datosCobro.body.site_id,
        site_transaction_id = datosCobro.body.site_transaction_id, //
        token = datosCobro.body.token,
        cupon = datosCobro.body.status_details.ticket, // no hay lote
        codigo_autorizacion = datosCobro.body.status_details.card_authorization_code
        Ipg_TransaccionID = datosCobro.body.id
        fecha_cupon = datosCobro.body.date
      }
      if (datosPago.ingresa_financiacion != undefined && datosPago.ingresa_financiacion != null){
        ingresa_financiacion = datosPago.ingresa_financiacion
      }
      if (datosPago.ingresa_tipo_tarjeta != undefined && datosPago.ingresa_tipo_tarjeta != null){
        ingresa_tipo_tarjeta = datosPago.ingresa_tipo_tarjeta
      }
      if (datosPago.ingresa_forma_pago != undefined && datosPago.ingresa_forma_pago != null){
        ingresa_forma_pago = datosPago.ingresa_forma_pago
      }
      unBoton = {
        idpago: datosPago.idpago,
        empresa: this.empresa,
        titular: this.cardName,
        documento_numero: this.documento,
        tarjeta_numero: this.cardNumber,
        mes_vto: this.cardMonth,
        anio: this.cardYear.toString().slice(-2),
        codigo_seguridad: this.cardCvv,
        cuotas: this.forma_pago.CUOTA_INTERNA,
        forma_pago: this.forma_pago,
        total: this.importe_total,
        correo: this.correo.toLowerCase().trim(),
        ingresa_financiacion: ingresa_financiacion,
        ingresa_tipo_tarjeta: ingresa_tipo_tarjeta,
        ingresa_forma_pago: ingresa_forma_pago,
        error_codigo: error_codigo,
        error_mensaje: error_mensaje,
        error_descripcion: error_descripcion,
        status: status,
        bin: bin,
        card_brand: card_brand,
        card_data: card_data,
        date: date,
        id: id, // transaccion_id
        site_id: site_id,
        site_transaction_id: site_transaction_id, //
        token: token,
        numero: cupon, // no hay lote
        codigo_autorizacion: codigo_autorizacion,
        Ipg_TransaccionID: Ipg_TransaccionID,
        fecha_cupon: fecha_cupon
      }
      // actualizo en firebase
      let updatePeticion = await this.$store.dispatch('botonPago/completarBotonPago', {boton: unBoton, error: error, tipoTransaccion: 1, datosBoton: this.datosBoton, empresa: this.empresa})
      if (updatePeticion.resultado == 0){
        // error al actualizar el botón
        this.$store.state.msjSnackBar = 'No se pudo actualizar el botón de pago con la info del cobro: ' + updatePeticion.msj
        this.$store.state.colorSnackBar = 'error'
        this.$store.state.snackBarActivo = true
        return
      }
      // completar el proceso con el envio de mail en caso que corresponda
      if (parseInt(error) == 0){
        this.tituloModal = 'Finalizando pago ...'
        this.modalSendPago = true
        setTimeout(() => [this.enviarMail(datosCobro, unBoton)], 3000)
      }
    },
    async enviarMail(datosCobro, unBoton){
      // armo los parametros que necesito, tener en cuenta la empresa para saber qué template usar, aqui la plantilla siempre va a ser 1, el 1 quiere decir que vamos a enviar un comprobante de cobro, para todas las empresas se va a enviar un comprobante de cobro
      let templateId = 0
      let carpetaTemplate = ''
      if (this.empresa == 1){
        templateId = 3
        carpetaTemplate = 'DycarCitroen'
      }else if (this.empresa == 2){
        templateId = 1
        carpetaTemplate = 'CeluShop'
      }else if (this.empresa == 3){
        templateId = 4
        carpetaTemplate = 'DycarChevrolet'
      }else if (this.empresa == 5){
        templateId = 2
        carpetaTemplate = 'Rada'
      }else if (this.empresa == 15){
        templateId = 12
        carpetaTemplate = 'EuroDycar'
      }else{
        // sera dycar citroen? empresa 12
        templateId = 3
        carpetaTemplate = 'DycarCitroen'
      }
      let body = {
        remitente: 'no-responder@borigenbetzel.com.ar',
        asunto: '¡Cobro Exitoso!',
        destinatario: this.correo,
        proceso: 'Botón de Pago',
        pedido_numero: datosCobro.body.id,
        id_template: templateId, // esto es el id del template que depende de la empresa
        carpeta_template: carpetaTemplate, // esto es la carpeta contenedora del template que vamos a usar
        casilla_id: 1,
        parametros: [
          {
            nombreParam: 'nombre',
            valorParam: unBoton.titular
          },
          {
            nombreParam: 'importe',
            valorParam: unBoton.total
          }
        ],
        archivos_adjuntos: [
          {
            plantilla_id: 1 // esto es el comprobante de pago
          }
        ]
      }
      let mailPeticion = await this.$store.dispatch('cobros/sendEmailPetition', body)
      this.modalSendPago = false
      // redireccionar
      setTimeout(() => [window.location.href = this.datosEmpresa.redireccion], 3000)
    },
    async completarAnulacionBotonPago(datosBoton){
      // paso a actualizar
      this.$store.state.modalCarga = true
      let updatePeticion = await this.$store.dispatch('botonPago/completarAnulacion', {key: datosBoton.id, empresa: this.empresa})
      this.$store.state.modalCarga = false
      if (updatePeticion.resultado == 0){
        this.$store.dispatch('executeModalAviso', {titulo: 'Completar Datos Cobro Anulado', texto: 'El cobro se anuló pero no se pudo actualizar los datos del botón de pago: ' + updatePeticion.msj, icono: 'fas fa-exclamation-circle', color: 'warning'})
      }else{
        this.$store.dispatch('executeModalAviso', {titulo: 'Completar Datos Cobro Anulado', texto: 'Se completaron los Datos de Anulación Exitosamente.', icono: 'fas fa-exclamation-circle', color: 'success'})
      }
      setTimeout(() => [window.location.href = this.datosEmpresa.redireccion], 3000)
    },
  },
  watch: {
    estadosCobro: function(){
      if (this.estadosCobro.length > 0){
        for (let id in this.estadosCobro){
          // recorro y comparo con el estado del boton que estoy por realizar
          if (this.estadosCobro[id].descripcion.toUpperCase() == this.datosBoton.estado.toUpperCase()){
            if (this.estadosCobro[id].link_accesible == 1){
              this.link_accesible = 1
              break
            }
          }
        }
        this.validarLink()
      }
    }
  },
}
</script>

<style>

</style>